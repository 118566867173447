import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

export const query = graphql`
  query HomeQ {
    sanityPage(home: { eq: true }) {
      title
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
    }
    # allSanityProject {
    #   nodes {
    #     _type
    #     title
    #     slug {
    #       current
    #     }
    #   }
    # }
  }
`

const IndexPage = ({ data }) => {
  const { seo } = data.sanityPage
  // console.log(seo)
  // const { nodes } = data.allSanityProject

  return (
    <div className="page py-header-height px-md">
      <Seo
        metaTitle={seo.metaTitle}
        metaDescription={seo.metaDescription}
        metaImage={seo.metaImage?.asset.url}
        template={`template-home`}
        page={false}
      />

      {/* {nodes.map((el, i) => (
        <div className="project" key={i}>
          <Link to={_linkResolver(el)}>{el.title}</Link>
        </div>
      ))} */}
    </div>
  )
}

export default IndexPage
